import { Helmet } from "react-helmet";
import { Text, Heading, Img } from "../../../components/Dashboard";
import Footer from "../../../components/Dashboard/Footer";
import Header from "../../../components/Dashboard/Header";
import { ReactTable } from "../../../components/Dashboard/ReactTable";
import PaidDashboardSection from "./PaidDashboardSection";
import { DashboardSection } from "./DashboardSection";
import { createColumnHelper } from "@tanstack/react-table";
import React, { useCallback, useEffect, useState } from "react";
import Sidebar1 from "components/Dashboard/Sidebar1";
import {
  BarChart,
  Bar,
  XAxis,
  Tooltip,
  ResponsiveContainer,
  Cell,
} from "recharts";
import { useAuth } from "components/auth/AuthProvider";
import { NotificationComponent } from "components/notifications/notificationProvider";
import { TableNoPag } from "components/Dashboard/TableNoPag";
import { useLazyQuery } from "@apollo/client";
import { FETCH_USER_REQUEST } from "components/queries/allQueries";
import {
  arraysMatchUnordered,
  calculateAverageAO,
  convertMarketOption,
  OptimizerData,
} from "../Paid-Opti";

const tableData = [
  { playHeader: "Lebron 022.5 points", lineHeader: "-200", logicHeader: "+EV" },
  { playHeader: "Lebron 022.5 points", lineHeader: "-250", logicHeader: "+EV" },
  { playHeader: "Lebron 022.5 points", lineHeader: "-300", logicHeader: "+EV" },
  { playHeader: "Lebron 022.5 points", lineHeader: "-280", logicHeader: "+EV" },
  { playHeader: "Lebron 022.5 points", lineHeader: "-140", logicHeader: "+EV" },
  { playHeader: "Lebron 022.5 points", lineHeader: "-590", logicHeader: "+EV" },
];

const data = [
  { name: "Mon", Profit: 45 },
  { name: "Tue", Profit: 87 },
  { name: "Wed", Profit: 131 },
  { name: "Thu", Profit: 39 },
  { name: "Fri", Profit: 142 },
  { name: "Sat", Profit: 163 },
  { name: "sun", Profit: 84 },
];

type TableRowType = {
  playHeader: string;
  lineHeader: string;
  logicHeader: string;
};

type Table3RowType = {
  averageLabel: any;
  teamLabel: string;
  bettypeLabel: string;
  booksLabel: any;
  handicapLabel: string;
  idLabel: string;
  leagueLabel: string;
  marketLabel: string;
  nameLabel: string;
  startTimeLabel: string;
  startedLabel: boolean;
  statesLabel: any;
  tagsLabel: string[];
};

type UserData = {
  id: String;
  username: String;
  email: String;
  customerId: String;
  avatar: string;
  lastLogin: Number;
  registerDate: Number;
  subscriptions: Subscriptions[];
};

type Subscriptions = {
  id: String;
  product: String;
  expiration: Number;
  expired: Boolean;
  plan: String;
  price: Number;
};

export default function PaidDashboard() {
  const { userState, tokens } = useAuth();
  const [user, setUser] = useState<UserData | undefined>(undefined);
  const [activeArbs, setActiveArbs] = useState(0);
  const [activePositiveEv, setActivePositiveEv] = useState(0);
  const [activeOptimizer, setActiveOptimizer] = useState(0);
  const [activeReferralLinks, setActiveReferralLink] = useState(0);
  const [optimizerSneakPeak, setActiveSneakPeak] = useState<Table3RowType[]>(
    [],
  );
  const [fetchUser] = useLazyQuery(FETCH_USER_REQUEST);

  const handleFetchUser = useCallback(async () => {
    if (!tokens.accessToken) return;
    try {
      const response = await fetchUser({
        variables: {
          accessToken: tokens.accessToken,
        },
      });
      if (response.error) {
        console.error("Fetching user failed:", response.error);
      } else if (response.data.fetchUser.success) {
        setUser(response.data.fetchUser.data.userData);
        setActiveArbs(response.data.fetchUser.data.activeArbs);
        setActiveOptimizer(response.data.fetchUser.data.activeOptimizer);
        setActivePositiveEv(response.data.fetchUser.data.activePositiveEv);
        setActiveReferralLink(0);

        const newOdds: Table3RowType[] = [];
        for (const odds of response.data.fetchUser.data
          .optimizerSneakPeak as OptimizerData[]) {
          newOdds.push({
            averageLabel: calculateAverageAO(odds.books),
            teamLabel: `${odds.away} @ ${odds.home}`,
            bettypeLabel: convertMarketOption(odds.bettype),
            booksLabel: odds.books,
            handicapLabel: odds.handicap,
            idLabel: odds.id,
            leagueLabel: odds.league,
            marketLabel: odds.market,
            nameLabel: odds.name,
            startTimeLabel: odds.startTime,
            startedLabel: odds.started,
            statesLabel: odds.states,
            tagsLabel: odds.tags,
          });
        }
        if (!arraysMatchUnordered(newOdds, optimizerSneakPeak)) {
          setActiveSneakPeak(newOdds);
        }

        console.log("Fetching user successful", response.data);
      } else {
        console.error("Fetching user failed:", response.data.fetchUser.error);
      }
    } catch (err) {
      console.error("Error Fetching user:", err);
    }
  }, [fetchUser, tokens, optimizerSneakPeak]);

  useEffect(() => {
    handleFetchUser();
  }, [handleFetchUser, tokens]);

  const tableColumns = React.useMemo(() => {
    const tableColumnHelper = createColumnHelper<TableRowType>();
    return [
      tableColumnHelper.accessor("playHeader", {
        cell: (info) => (
          <Text
            as="p"
            className="pl-[26px] text-[14px] font-normal text-neutral-neutral_1100_day_ sm:pl-5"
          ></Text>
        ),
        header: () => (
          <Text
            as="p"
            className="pb-2.5 pl-[26px] text-left text-[14px] font-normal text-neutral-neutral_400_day_ sm:pl-5"
          >
            Play
          </Text>
        ),
        meta: { width: "280px" },
      }),
      tableColumnHelper.accessor("lineHeader", {
        cell: (info) => (
          <Text
            as="p"
            className="text-[14px] font-normal text-neutral-neutral_1100_day_"
          ></Text>
        ),
        header: () => (
          <Text
            as="p"
            className="pb-3 text-left text-[14px] font-normal text-neutral-neutral_400_day_"
          >
            Line
          </Text>
        ),
        meta: { width: "132px" },
      }),
      tableColumnHelper.accessor("logicHeader", {
        cell: (info) => (
          <Text
            as="p"
            className="text-[14px] font-normal text-neutral-neutral_1100_day_"
          ></Text>
        ),
        header: () => (
          <Text
            as="p"
            className="pb-2.5 text-left text-[14px] font-normal text-neutral-neutral_400_day_"
          >
            Logic
          </Text>
        ),
        meta: { width: "110px" },
      }),
    ];
  }, []);

  const tableColumnsOpti = React.useMemo(() => {
    const table3ColumnHelper = createColumnHelper<Table3RowType>();
    return [
      table3ColumnHelper.accessor("leagueLabel", {
        cell: (info) => (
          <div className="flex items-center gap-2">
            {info.getValue<any>() === "WNBA" && (
              <img
                alt=""
                src="./Images/BooksIcon/wnba.jpeg"
                style={{ width: "10%", height: "15%" }}
              />
            )}
            {info.getValue<any>() === "NBA" && (
              <img
                alt=""
                src="./Images/BooksIcon/nba.svg"
                style={{ width: "10%", height: "15%" }}
              />
            )}
            {info.getValue<any>() === "NFL" && (
              <img
                alt=""
                src="./Images/BooksIcon/nfl.jpeg"
                style={{ width: "10%", height: "15%" }}
              />
            )}
            {info.getValue<any>() === "MLB" && (
              <img
                alt=""
                src="./Images/BooksIcon/mlb.jpeg"
                style={{ width: "15%", height: "15%" }}
              />
            )}

            <Text
              as="p"
              className="text-[14px] font-normal text-neutral-neutral_1100__day_ "
            >
              {info.getValue<any>()}
            </Text>
          </div>
        ),
        header: (info) => (
          <Text
            as="p"
            className="pb-3  text-left text-[14px] font-normal text-neutral-neutral_500__day_"
          >
            League
          </Text>
        ),
        meta: { width: "8%" },
      }),
      table3ColumnHelper.accessor("teamLabel", {
        cell: (info) => (
          <Text
            as="p"
            className="text-[14px] font-normal text-neutral-neutral_1100__day_"
          >
            {info.getValue<any>()}
          </Text>
        ),
        header: (info) => (
          <Text
            as="p"
            className="pb-3  text-left text-[14px] font-normal text-neutral-neutral_500__day_"
          >
            Game
          </Text>
        ),
        meta: { width: "10%" },
      }),
      table3ColumnHelper.accessor("bettypeLabel", {
        cell: (info) => (
          <Text
            as="p"
            className="text-[14px] font-normal text-neutral-neutral_1100__day_"
          >
            {info.getValue<any>()}
          </Text>
        ),
        header: (info) => (
          <Text
            as="p"
            className="pb-3  text-left text-[14px] font-normal text-neutral-neutral_500__day_"
          >
            Bet Type
          </Text>
        ),
        meta: { width: "8%" },
      }),
      table3ColumnHelper.accessor("handicapLabel", {
        cell: (info) => (
          <Text
            as="p"
            className="text-[14px] font-normal text-neutral-neutral_1100__day_"
          >
            {info.getValue<any>()}
          </Text>
        ),
        header: (info) => (
          <Text
            as="p"
            className="pb-3  text-left text-[14px] font-normal text-neutral-neutral_500__day_"
          >
            Handicap
          </Text>
        ),
        meta: { width: "7%" },
      }),
      table3ColumnHelper.accessor("marketLabel", {
        cell: (info) => (
          <Text
            as="p"
            className="text-[14px] font-normal text-neutral-neutral_1100__day_"
          >
            {info.getValue<any>()}
          </Text>
        ),
        header: (info) => (
          <Text
            as="p"
            className="pb-3 text-left text-[14px] font-normal text-neutral-neutral_500__day_"
          >
            Market
          </Text>
        ),
        meta: { width: "8%" },
      }),
      table3ColumnHelper.accessor("nameLabel", {
        cell: (info) => (
          <Text
            as="p"
            className="text-[14px] font-normal text-neutral-neutral_1100__day_"
          >
            {info.getValue<any>()}
          </Text>
        ),
        header: (info) => (
          <Text
            as="p"
            className="pb-3 text-left text-[14px] font-normal text-neutral-neutral_500__day_"
          >
            Name
          </Text>
        ),
        meta: { width: "9%" },
      }),
      table3ColumnHelper.accessor("averageLabel", {
        cell: (info) => {
          const currentValue =
            Number(Number(info.getValue<any>()).toFixed(0)) || 0;

          const allValues = Object.values(info.row.original.booksLabel).map(
            (book: any) => book?.ao || 0,
          );

          const maxValue = Math.max(...allValues);

          return (
            <button
              className="w-[100%] rounded-lg h-[28px] border"
              style={{
                backgroundColor:
                  currentValue === maxValue
                    ? "rgba(80, 209, 178, 0.2)"
                    : currentValue < 0
                      ? "#E8EDF2"
                      : "#E8EDF2",
                color:
                  currentValue === maxValue
                    ? "#2B9B80"
                    : currentValue < 0
                      ? "black"
                      : "black",
              }}
              disabled={!info.getValue<any>()}
              onClick={() =>
                window.open(info.getValue<any>()?.wl || "#", "_blank")
              }
            >
              {currentValue || 0}
            </button>
          );
        },
        header: (info) => (
          <img
            alt=""
            src="./Images/BooksIcon/LogoDark.png"
            style={{ width: "30%", height: "15%", marginLeft: "25%" }}
          />
        ),
        meta: { width: "4%" },
      }),
      table3ColumnHelper.accessor("booksLabel.fanduel", {
        cell: (info) => {
          const currentValue = info.getValue<any>()?.ao || 0;

          const allValues = Object.values(info.row.original.booksLabel).map(
            (book: any) => book.ao || 0,
          );

          const maxValue = Math.max(...allValues);

          return (
            <button
              className="w-[100%] rounded-lg h-[28px] border"
              style={{
                backgroundColor:
                  currentValue === maxValue
                    ? "rgba(80, 209, 178, 0.2)"
                    : currentValue < 0
                      ? "#E8EDF2"
                      : "#E8EDF2",
                color:
                  currentValue === maxValue
                    ? "#2B9B80"
                    : currentValue < 0
                      ? "black"
                      : "black",
              }}
              disabled={!info.getValue<any>()}
              onClick={() =>
                window.open(info.getValue<any>()?.wl || "#", "_blank")
              }
            >
              {currentValue || 0}
            </button>
          );
        },
        header: (info) => (
          <img
            alt=""
            src="./Images/BooksIcon/fanduel.png"
            style={{ width: "30%", height: "15%", marginLeft: "25%" }}
          />
        ),
        meta: { width: "4%" },
      }),
      table3ColumnHelper.accessor("booksLabel.draftkings", {
        cell: (info) => {
          const currentValue = info.getValue<any>()?.ao || 0;

          const allValues = Object.values(info.row.original.booksLabel).map(
            (book: any) => book.ao || 0,
          );

          const maxValue = Math.max(...allValues);

          return (
            <button
              className="w-[100%] rounded-lg h-[28px] border"
              style={{
                backgroundColor:
                  currentValue === maxValue
                    ? "rgba(80, 209, 178, 0.2)"
                    : currentValue < 0
                      ? "#E8EDF2"
                      : "#E8EDF2",
                color:
                  currentValue === maxValue
                    ? "#2B9B80"
                    : currentValue < 0
                      ? "black"
                      : "black",
              }}
              disabled={!info.getValue<any>()}
              onClick={() =>
                window.open(info.getValue<any>()?.wl || "#", "_blank")
              }
            >
              {currentValue || 0}
            </button>
          );
        },
        header: (info) => (
          <img
            alt=""
            src="./Images/BooksIcon/draftkings.png"
            style={{ width: "45%", height: "20%", marginLeft: "25%" }}
          />
        ),
        meta: { width: "4%" },
      }),
      table3ColumnHelper.accessor("booksLabel.betmgm", {
        cell: (info) => {
          const currentValue = info.getValue<any>()?.ao || 0;

          const allValues = Object.values(info.row.original.booksLabel).map(
            (book: any) => book.ao || 0,
          );

          const maxValue = Math.max(...allValues);

          return (
            <button
              className="w-[100%] rounded-lg h-[28px] border"
              style={{
                backgroundColor:
                  currentValue === maxValue
                    ? "rgba(80, 209, 178, 0.2)"
                    : currentValue < 0
                      ? "#E8EDF2"
                      : "#E8EDF2",
                color:
                  currentValue === maxValue
                    ? "#2B9B80"
                    : currentValue < 0
                      ? "black"
                      : "black",
              }}
              disabled={!info.getValue<any>()}
              onClick={() =>
                window.open(info.getValue<any>()?.wl || "#", "_blank")
              }
            >
              {currentValue || 0}
            </button>
          );
        },
        header: (info) => (
          <img
            alt=""
            src="./Images/BooksIcon/BetMGM.png"
            style={{ width: "35%", height: "15%", marginLeft: "25%" }}
          />
        ),
        meta: { width: "4%" },
      }),
      table3ColumnHelper.accessor("booksLabel.bet365", {
        cell: (info) => {
          const currentValue = info.getValue<any>()?.ao || 0;

          const allValues = Object.values(info.row.original.booksLabel).map(
            (book: any) => book.ao || 0,
          );

          const maxValue = Math.max(...allValues);

          return (
            <button
              className="w-[100%] rounded-lg h-[28px] border"
              style={{
                backgroundColor:
                  currentValue === maxValue
                    ? "rgba(80, 209, 178, 0.2)"
                    : currentValue < 0
                      ? "#E8EDF2"
                      : "#E8EDF2",
                color:
                  currentValue === maxValue
                    ? "#2B9B80"
                    : currentValue < 0
                      ? "black"
                      : "black",
              }}
              disabled={!info.getValue<any>()}
              onClick={() =>
                window.open(info.getValue<any>()?.wl || "#", "_blank")
              }
            >
              {currentValue || 0}
            </button>
          );
        },
        header: (info) => (
          <img
            alt=""
            src="./Images/BooksIcon/bet365.png"
            style={{ width: "40%", height: "3%", marginLeft: "25%" }}
          />
        ),
        meta: { width: "4%" },
      }),
      table3ColumnHelper.accessor("booksLabel.fanatics", {
        cell: (info) => {
          const currentValue = info.getValue<any>()?.ao || 0;

          const allValues = Object.values(info.row.original.booksLabel).map(
            (book: any) => book.ao || 0,
          );

          const maxValue = Math.max(...allValues);

          return (
            <button
              className="w-[100%] rounded-lg h-[28px] border"
              style={{
                backgroundColor:
                  currentValue === maxValue
                    ? "rgba(80, 209, 178, 0.2)"
                    : currentValue < 0
                      ? "#E8EDF2"
                      : "#E8EDF2",
                color:
                  currentValue === maxValue
                    ? "#2B9B80"
                    : currentValue < 0
                      ? "black"
                      : "black",
              }}
              disabled={!info.getValue<any>()}
              onClick={() =>
                window.open(info.getValue<any>()?.wl || "#", "_blank")
              }
            >
              {currentValue || 0}
            </button>
          );
        },
        header: (info) => (
          <img
            alt=""
            src="./Images/BooksIcon/fanatics.webp"
            style={{ width: "40%", height: "3%", marginLeft: "25%" }}
          />
        ),
        meta: { width: "4%" },
      }),
      table3ColumnHelper.accessor("booksLabel.espnbet", {
        cell: (info) => {
          const currentValue = info.getValue<any>()?.ao || 0;

          const allValues = Object.values(info.row.original.booksLabel).map(
            (book: any) => book.ao || 0,
          );

          const maxValue = Math.max(...allValues);

          return (
            <button
              className="w-[100%] rounded-lg h-[28px] border"
              style={{
                backgroundColor:
                  currentValue === maxValue
                    ? "rgba(80, 209, 178, 0.2)"
                    : currentValue < 0
                      ? "#E8EDF2"
                      : "#E8EDF2",
                color:
                  currentValue === maxValue
                    ? "#2B9B80"
                    : currentValue < 0
                      ? "black"
                      : "black",
              }}
              disabled={!info.getValue<any>()}
              onClick={() =>
                window.open(info.getValue<any>()?.wl || "#", "_blank")
              }
            >
              {currentValue || 0}
            </button>
          );
        },
        header: (info) => (
          <img
            alt=""
            src="./Images/BooksIcon/espnbet.jpeg"
            style={{ width: "40%", height: "3%", marginLeft: "25%" }}
          />
        ),
        meta: { width: "4%" },
      }),
      table3ColumnHelper.accessor("booksLabel.caesars", {
        cell: (info) => {
          const currentValue = info.getValue<any>()?.ao || 0;

          const allValues = Object.values(info.row.original.booksLabel).map(
            (book: any) => book.ao || 0,
          );

          const maxValue = Math.max(...allValues);

          return (
            <button
              className="w-[100%] rounded-lg h-[28px] border"
              style={{
                backgroundColor:
                  currentValue === maxValue
                    ? "rgba(80, 209, 178, 0.2)"
                    : currentValue < 0
                      ? "#E8EDF2"
                      : "#E8EDF2",
                color:
                  currentValue === maxValue
                    ? "#2B9B80"
                    : currentValue < 0
                      ? "black"
                      : "black",
              }}
              disabled={!info.getValue<any>()}
              onClick={() =>
                window.open(info.getValue<any>()?.wl || "#", "_blank")
              }
            >
              {currentValue || 0}
            </button>
          );
        },
        header: (info) => (
          <img
            alt=""
            src="./Images/BooksIcon/caesars.webp"
            style={{ width: "40%", height: "3%", marginLeft: "25%" }}
          />
        ),
        meta: { width: "4%" },
      }),
      table3ColumnHelper.accessor("booksLabel.pinnacle", {
        cell: (info) => {
          const currentValue = info.getValue<any>()?.ao || 0;

          const allValues = Object.values(info.row.original.booksLabel).map(
            (book: any) => book.ao || 0,
          );

          const maxValue = Math.max(...allValues);

          return (
            <button
              className="w-[100%] rounded-lg h-[28px] border"
              style={{
                backgroundColor:
                  currentValue === maxValue
                    ? "rgba(80, 209, 178, 0.2)"
                    : currentValue < 0
                      ? "#E8EDF2"
                      : "#E8EDF2",
                color:
                  currentValue === maxValue
                    ? "#2B9B80"
                    : currentValue < 0
                      ? "black"
                      : "black",
              }}
              disabled={!info.getValue<any>()}
              onClick={() =>
                window.open(info.getValue<any>()?.wl || "#", "_blank")
              }
            >
              {currentValue || 0}
            </button>
          );
        },
        header: (info) => (
          <img
            alt=""
            src="./Images/BooksIcon/Pinnacle.jpeg"
            style={{ width: "35%", height: "8%", marginLeft: "25%" }}
          />
        ),
        meta: { width: "4%" },
      }),
      table3ColumnHelper.accessor("booksLabel.fliff", {
        cell: (info) => {
          const currentValue = info.getValue<any>()?.ao || 0;
          const allValues = Object.values(info.row.original.booksLabel).map(
            (book: any) => book.ao || 0,
          );

          const maxValue = Math.max(...allValues);

          return (
            <button
              className="w-[100%] rounded-lg h-[28px] border"
              style={{
                backgroundColor:
                  currentValue === maxValue
                    ? "rgba(80, 209, 178, 0.2)"
                    : currentValue < 0
                      ? "#E8EDF2"
                      : "#E8EDF2",
                color:
                  currentValue === maxValue
                    ? "#2B9B80"
                    : currentValue < 0
                      ? "black"
                      : "black",
              }}
              disabled={!info.getValue<any>()}
              onClick={() =>
                window.open(info.getValue<any>()?.wl || "#", "_blank")
              }
            >
              {currentValue || 0}
            </button>
          );
        },
        header: (info) => (
          <img
            alt=""
            src="./Images/BooksIcon/getfliff.jpeg"
            style={{ width: "35%", height: "12%", marginLeft: "25%" }}
          />
        ),
        meta: { width: "4%" },
      }),
      table3ColumnHelper.accessor("booksLabel.betrivers", {
        cell: (info) => {
          const currentValue = info.getValue<any>()?.ao || 0;

          const allValues = Object.values(info.row.original.booksLabel).map(
            (book: any) => book.ao || 0,
          );

          const maxValue = Math.max(...allValues);

          return (
            <button
              className="w-[100%] rounded-lg h-[28px] border"
              style={{
                backgroundColor:
                  currentValue === maxValue
                    ? "rgba(80, 209, 178, 0.2)"
                    : currentValue < 0
                      ? "#E8EDF2"
                      : "#E8EDF2",
                color:
                  currentValue === maxValue
                    ? "#2B9B80"
                    : currentValue < 0
                      ? "black"
                      : "black",
              }}
              disabled={!info.getValue<any>()}
              onClick={() =>
                window.open(info.getValue<any>()?.wl || "#", "_blank")
              }
            >
              {currentValue || 0}
            </button>
          );
        },
        header: (info) => (
          <img
            alt=""
            src="./Images/BooksIcon/BetRivers.jpeg"
            style={{ width: "35%", height: "7%", marginLeft: "25%" }}
          />
        ),
        meta: { width: "4%" },
      }),
      table3ColumnHelper.accessor("booksLabel.hardrock", {
        cell: (info) => {
          const currentValue = info.getValue<any>()?.ao || 0;

          const allValues = Object.values(info.row.original.booksLabel).map(
            (book: any) => book.ao || 0,
          );

          const maxValue = Math.max(...allValues);

          return (
            <button
              className="w-[100%] rounded-lg h-[28px] border"
              style={{
                backgroundColor:
                  currentValue === maxValue
                    ? "rgba(80, 209, 178, 0.2)"
                    : currentValue < 0
                      ? "#E8EDF2"
                      : "#E8EDF2",
                color:
                  currentValue === maxValue
                    ? "#2B9B80"
                    : currentValue < 0
                      ? "black"
                      : "black",
              }}
              disabled={!info.getValue<any>()}
              onClick={() =>
                window.open(info.getValue<any>()?.wl || "#", "_blank")
              }
            >
              {currentValue || 0}
            </button>
          );
        },

        header: (info) => (
          <img
            alt=""
            src="./Images/BooksIcon/hardrock.webp"
            style={{ width: "50%", height: "4%", marginLeft: "25%" }}
          />
        ),
        meta: { width: "4%" },
      }),
    ];
  }, []);

  console.log(userState)
  
  if (!user) {
    return (
      <>
        <text>Loading...</text>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>Elite Dashboard - Advanced Betting Analytics</title>
        <meta
          name="description"
          content="Elevate your betting game with the Elite Dashboard. Get access to exclusive Arbitrage opportunities, Model Projections, and +EV Plays for top-tier betting performance."
        />
      </Helmet>
      <NotificationComponent
        title={`Welcome Back ${user?.username}`}
        message={
          "We missed you! make sure to check out our latest feature (Positive"
        }
        type={"success"}
      />
      <div className="flex">
        <Sidebar1 />
        <div className="flex-1 bg-neutral-neutral_100__day_ relative">
          <div className="relative h-[100px] md:h-auto">
            <Header />
          </div>
          <div className="mr-8 ml-5 flex items-start gap-[22px] md:mr-0">
            <div className="flex flex-1 flex-col gap-[18px]">
              <div className="flex flex-col gap-6">
                <DashboardSection
                  activeList={[
                    {
                      arbitrageDescription: "Arbitrage",
                      activeArbsText: `${activeArbs} Active Arbs`,
                      priceText: "$156",
                      averageArbsText: "Average arbs per day: 239",
                    },
                    {
                      arbitrageDescription: "Optimizer",
                      activeArbsText: `${activeOptimizer} Active Odds`,
                      priceText: "$156",
                      averageArbsText: "Average arbs per day: 239",
                    },
                    {
                      arbitrageDescription: "Arbitrage",
                      activeArbsText: `${activePositiveEv} Active +Ev`,
                      priceText: "$156",
                      averageArbsText: "Average arbs per day: 239",
                    },
                    {
                      arbitrageDescription: "Referrals Links",
                      activeArbsText: `${activeReferralLinks} Links`,
                      priceText: "$156",
                      averageArbsText: "$4900 in Free Bets Available",
                    },
                  ]}
                />
              </div>

              <div className="flex flex-1  gap-[18px]">
                <div className="flex flex-1 flex-col w-[49%] gap-8 rounded-[16px] border border-solid border-neutral-neutral_border_day_ bg-neutral-neutral_bg__day_ p-10 md:self-stretch md:px-5">
                  <div className=" mt-.5 flex flex-col items-start justify-center gap-2.5 md:ml-0">
                    <Heading
                      size="subtitle_semi_bold"
                      as="h6"
                      className="text-[16px] font-semibold text-neutral-neutral_1100__day_"
                    >
                      Optimizer
                    </Heading>
                    <div className="mr-2 h-px self-stretch bg-gray-100 md:mr-0" />
                  </div>
                  <div className="mx-1.5 md:mx-0">
                    <ReactTable
                      size="sm"
                      bodyProps={{ className: "" }}
                      cellProps={{
                        className:
                          "border-neutral-neutral_border_day_ border-b border-solid",
                      }}
                      className="mb-[26px]"
                      columns={tableColumnsOpti}
                      data={optimizerSneakPeak}
                    />
                  </div>
                </div>
                <PaidDashboardSection />
              </div>

              <div className="ml-1 grid grid-cols-2 gap-5 0md:ml-0">
                <div className="mr-1.5 flex gap-[18px] md:mr-0 md:flex-col">
                  <div className="flex w-[100%] flex-col gap-6 rounded-[16px] border border-solid border-neutral-neutral_border__day_ bg-neutral-neutral_bg__day_ p-[22px] md:w-full md:px-5 sm:p-5">
                    <div className="flex items-start justify-between  gap-5">
                      <Heading
                        size="subtitle_semi_bold"
                        as="h4"
                        className="self-center text-[16px] font-semibold text-neutral-neutral_1100__day_"
                      >
                        Arbitrage T-7 Results
                      </Heading>
                      <Img
                        src="/Images/Dashboard-img/Toggle.png"
                        alt="Toggle Icon"
                        className="mr-[172px] mt-1 h-[12px]"
                      />
                    </div>
                    <div>
                      <ResponsiveContainer width="100%" height={400}>
                        <BarChart
                          data={data}
                          margin={{
                            top: 20,
                            right: 30,
                            left: 20,
                            bottom: 5,
                          }}
                        >
                          <XAxis dataKey="name" tickLine={false} />
                          <Tooltip />
                          <Bar
                            dataKey="Profit"
                            fill="#8884d8"
                            radius={[20, 20, 0, 0]}
                          >
                            {data.map((entry, index) => (
                              <Cell key={`cell-${index}`} fill="#8884d8" />
                            ))}
                          </Bar>
                        </BarChart>
                      </ResponsiveContainer>
                    </div>
                    <Heading
                      size="heading_h2"
                      as="h2"
                      className="mb-2 text-[32px] font-extrabold leading-[38px] text-accents-green md:text-[30px] sm:text-[28px]"
                    >
                      <span className="text-accents-green">$691&nbsp;</span>
                      <span className="text-black-900">of&nbsp;</span>
                      <span className="text-accents-green">
                        arbitrage profit
                      </span>
                      <span className="text-black-900">
                        &nbsp;in the last&nbsp;
                      </span>
                      <span className="text-accents-green">7 days</span>
                      <span className="text-black-900">.&nbsp;</span>
                    </Heading>
                  </div>
                </div>
                <div className="flex flex-1 flex-col w-auto gap-8 rounded-[16px] border border-solid border-neutral-neutral_border_day_ bg-neutral-neutral_bg__day_ p-10 md:self-stretch md:px-5">
                  <div className=" mt-.5 flex flex-col items-start justify-center gap-2.5 md:ml-0">
                    <Heading
                      size="subtitle_semi_bold"
                      as="h6"
                      className="text-[16px] font-semibold text-neutral-neutral_1100__day_"
                    >
                      Parlay Builder
                    </Heading>
                    <div className="mr-2 h-px self-stretch bg-gray-100 md:mr-0" />
                  </div>
                  <div className="mx-1.5 md:mx-0">
                    <TableNoPag
                      size="sm"
                      bodyProps={{ className: "" }}
                      cellProps={{
                        className:
                          "border-neutral-neutral_border_day_ border-b border-solid",
                      }}
                      className="mb-[26px]"
                      columns={tableColumns}
                      data={tableData}
                    />
                  </div>
                </div>
              </div>

              <Footer />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
