export function americanToDecimalOdds(americanOdds: number): number {
    if (americanOdds >= 100) {
        // Positive odds (underdog)
        return (americanOdds / 100) + 1;
    } else {
        // Negative odds (favorite)
        return (100 / Math.abs(americanOdds)) + 1;
    }
}

export function decimalToAmericanOdds(decimalOdds: number): string {
    if (decimalOdds >= 2) {
        // Underdog
        const americanOdds = Math.round((decimalOdds - 1) * 100);
        return `+${americanOdds}`;
    } else {
        // Favorite
        const americanOdds = Math.round(-100 / (decimalOdds - 1));
        return `${americanOdds}`;
    }
}

export function calculatePayout(decimalOdds: number, wager: number): number {
    // Limit decimal places to 4
    decimalOdds = +decimalOdds.toFixed(4);
    const payout = decimalOdds * wager;
    return +payout.toFixed(2); // Limiting payout to 2 decimal places
}
