/* eslint-disable react-hooks/exhaustive-deps */
import "./Buttons.css";
import { Helmet } from "react-helmet";
import { Text, Img, Input } from "../../../components/Dashboard";
import { ReactTable } from "../../../components/Dashboard/ReactTable";

import { createColumnHelper } from "@tanstack/react-table";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Sidebar1 from "components/Dashboard/Sidebar1";
import { useLazyQuery } from "@apollo/client";
import { FETCH_OPTIMIZER_REQUEST } from "components/queries/allQueries";
import { useAuth } from "components/auth/AuthProvider";

import { Select } from "antd";
import Header from "components/Dashboard/Header";
import { CloseCircle,Refresh } from "iconsax-react";
import { americanToDecimalOdds, decimalToAmericanOdds } from "components/functions/math";

const { Option } = Select;

const AntSelectBox = ({ ...props }) => {
  return (
    <Select mode="multiple" maxTagCount={2} {...props} style={{ width: "25%" }}>
      {leagueOptions.map((option) => (
        <Option key={option.value} value={option.value}>
          {option.label}
        </Option>
      ))}
    </Select>
  );
};

// Uso d

const leagueOptions = [
  { label: "WNBA", value: "wnba" },
  { label: "NBA", value: "nba" },
  { label: "NFL", value: "nfl" },
  { label: "MLB", value: "mlb" },
];


const marketOptions: options[] = [
  { label: "Over/Under", value: "o/u" },
  { label: "Spread", value: "sp" },
  { label: "Moneyline", value: "ml" },
  { label: "Unknown", value: "uk" },
];

type options = {
  label: string;
  value: string;
};

type Table3RowType = {
  averageLabel: any;
  teamLabel: string;
  bettypeLabel: string;
  booksLabel: any;
  handicapLabel: string;
  idLabel: string;
  leagueLabel: string;
  marketLabel: string;
  nameLabel: string;
  startTimeLabel: string;
  startedLabel: boolean;
  statesLabel: any;
  tagsLabel: string[];
};

export type OptimizerData = {
  away: string;
  bettype: string;
  books: OPTBook;
  handicap: string;
  home: string;
  id: string;
  league: string;
  market: string;
  name: string;
  startTime: string;
  started: boolean;
  states?: any;
  tags: string[];
};

type OPTBook = {
  [key: string]: OptimizerBook;
};

type OptimizerBook = {
  ao: number;
  do: number;
  ml: string;
  wl: string;
};

export function arraysMatchUnordered(arr1: any[], arr2: any[]): boolean {
  if (arr1.length !== arr2.length) {
    return false;
  }
  const sortedArr1 = [...arr1].sort();
  const sortedArr2 = [...arr2].sort();

  return sortedArr1.every((value, index) => value === sortedArr2[index]);
}

export function convertMarketOption(opt: string): string {
  switch (opt) {
    case "OVER":
      return "Over";
    case "UNDER":
      return "Under";
    case "MONEYLINE":
      return "ML";
    case "SPREAD":
      return "SP";
    default:
      return "UK";
  }
}

export function calculateAverageAO(odds: any): string {
  const oddsArray = Object.values(odds); // Extract the values into an array
  if (oddsArray.length === 0) {
    return "0"; // Avoid division by zero
  }

  const totalAO: any = oddsArray.reduce((sum:number, item: any) => sum + americanToDecimalOdds(item.ao), 0);
  const averageAO = totalAO / oddsArray.length;

  return decimalToAmericanOdds(averageAO);
}


export default function OptimizerPage() {
  const [keywordOption, setKeywordOption] = useState("");
  const { tokens } = useAuth();
  const [odds, setOdds] = useState<Table3RowType[]>([]);
  const [filteredOdds, setFilteredOdds] = useState<Table3RowType[]>([]);
  const [leagueOption, setLeagueOption] = useState<options[]>([]);
  const [marketOption, setMarketOption] = useState<options[]>([]);
  const [countdown, setCountdown] = useState(15);
  const [fetchOptimizer] = useLazyQuery(FETCH_OPTIMIZER_REQUEST);
  const [isLive] = useState(true);

  const handleFetchOptimizer = useCallback(async () => {
    try {
      const response = await fetchOptimizer({
        variables: {
          accessToken: tokens.accessToken,
          filter: {
            sports: leagueOptions.map((option) => option.value),
            markets: marketOption.map((option) => option.value),
          },
        },
      });
      if (response.error) {
        console.error("Fetching optimizer failed:", response.error);
      } else if (response.data.fetchOptimizer.success) {
        const newOdds: Table3RowType[] = [];
        for (const odds of response.data.fetchOptimizer
          .data as OptimizerData[]) {

          newOdds.push({
            averageLabel: calculateAverageAO(odds.books),
            teamLabel: `${odds.away} @ ${odds.home}`,
            bettypeLabel: convertMarketOption(odds.bettype),
            booksLabel: odds.books,
            handicapLabel: odds.handicap,
            idLabel: odds.id,
            leagueLabel: odds.league,
            marketLabel: odds.market,
            nameLabel: odds.name,
            startTimeLabel: odds.startTime,
            startedLabel: odds.started,
            statesLabel: odds.states,
            tagsLabel: odds.tags,
          });
        }
        if (!arraysMatchUnordered(newOdds, odds)) {
          setOdds(newOdds);
        }

        console.log("Fetching optimizer successful", response.data.login);
      } else {
        console.error("Fetching optimizer failed:", response.data.login.error);
      }
    } catch (err) {
      console.error("Error fetching optimizer:", err);
    }
    setCountdown(15)
  }, [odds, fetchOptimizer, marketOption, tokens.accessToken]);

  useEffect(() => {
    let kw = keywordOption.trim().toLowerCase();
    let filteredOdds: Table3RowType[] = odds;
    if (!isLive) {
      filteredOdds = filteredOdds.filter((odds) => !odds.startedLabel);
    }

    if (marketOption && marketOption.length > 0) {
      filteredOdds = filteredOdds.filter((odds) =>
        marketOption.some(
          (option) => odds.bettypeLabel.toLowerCase() === String(option),
        ),
      );
    }

    if (leagueOption && leagueOption.length > 0) {
      filteredOdds = filteredOdds.filter((odds) =>
        leagueOption.some(
          (option) => odds.leagueLabel.toLowerCase() === String(option),
        ),
      );
    }

    if (kw && kw !== "") {
      filteredOdds = filteredOdds.filter(
        (odds) =>
          odds.nameLabel.toLowerCase().includes(kw) ||
          odds.leagueLabel.toLowerCase().includes(kw) ||
          odds.marketLabel.toLowerCase().includes(kw),
      );
    }

    setFilteredOdds(filteredOdds);
  }, [
    isLive,
    odds,
    setFilteredOdds,
    marketOption,
    leagueOption,
    keywordOption,
  ]);

  useEffect(() => {
    handleFetchOptimizer();
    const fa = setInterval(() => {
      handleFetchOptimizer();
      setCountdown(15);
    }, 15 * 1000); // every 3 seconds
    return () => clearInterval(fa);
  },[]);

  useEffect(() =>{
    const timer = setInterval(() => {
      setCountdown((prevCountdown) => (prevCountdown > 0 ? prevCountdown - 1 : 0));
    }, 1000);

    return () => clearInterval(timer)
  },[]);

  useEffect(() => {
    console.log("leagueOption changed", leagueOption);
  }, [leagueOption]);

  useEffect(() => {
    console.log("marketOption changed", marketOption);
  }, [marketOption]);

  const table3Columns = useMemo(() => {
    const table3ColumnHelper = createColumnHelper<Table3RowType>();
    return [
      table3ColumnHelper.accessor("leagueLabel", {
        cell: (info) => (
          <div className="flex items-center gap-2">
            {info.getValue<any>() === "WNBA" && (
              <img
                alt=""
                src="./Images/BooksIcon/wnba.jpeg"
                style={{ width: "10%", height: "15%" }}
              />
            )}
            {info.getValue<any>() === "NBA" && (
              <img
                alt=""
                src="./Images/BooksIcon/nba.svg"
                style={{ width: "10%", height: "15%" }}
              />
            )}
            {info.getValue<any>() === "NFL" && (
              <img
                alt=""
                src="./Images/BooksIcon/nfl.jpeg"
                style={{ width: "10%", height: "15%" }}
              />
            )}
            {info.getValue<any>() === "MLB" && (
              <img
                alt=""
                src="./Images/BooksIcon/mlb.jpeg"
                style={{ width: "15%", height: "15%" }}
              />
            )}

            <Text
              as="p"
              className="text-[14px] font-normal text-neutral-neutral_1100__day_ "
            >
              {info.getValue<any>()}
            </Text>
          </div>
        ),
        header: (info) => (
          <Text
            as="p"
            className="pb-3  text-left text-[14px] font-normal text-neutral-neutral_500__day_"
          >
            League
          </Text>
        ),
        meta: { width: "8%" },
      }),
      table3ColumnHelper.accessor("teamLabel", {
        cell: (info) => (
          <Text
            as="p"
            className="text-[14px] font-normal text-neutral-neutral_1100__day_"
          >
            {info.getValue<any>()}
          </Text>
        ),
        header: (info) => (
          <Text
            as="p"
            className="pb-3  text-left text-[14px] font-normal text-neutral-neutral_500__day_"
          >
            Game
          </Text>
        ),
        meta: { width: "10%" },
      }),
      table3ColumnHelper.accessor("bettypeLabel", {
        cell: (info) => (
          <Text
            as="p"
            className="text-[14px] font-normal text-neutral-neutral_1100__day_"
          >
            {info.getValue<any>()}
          </Text>
        ),
        header: (info) => (
          <Text
            as="p"
            className="pb-3  text-left text-[14px] font-normal text-neutral-neutral_500__day_"
          >
            Bet Type
          </Text>
        ),
        meta: { width: "8%" },
      }),
      table3ColumnHelper.accessor("handicapLabel", {
        cell: (info) => (
          <Text
            as="p"
            className="text-[14px] font-normal text-neutral-neutral_1100__day_"
          >
            {info.getValue<any>()}
          </Text>
        ),
        header: (info) => (
          <Text
            as="p"
            className="pb-3  text-left text-[14px] font-normal text-neutral-neutral_500__day_"
          >
            Handicap
          </Text>
        ),
        meta: { width: "7%" },
      }),
      table3ColumnHelper.accessor("marketLabel", {
        cell: (info) => (
          <Text
            as="p"
            className="text-[14px] font-normal text-neutral-neutral_1100__day_"
          >
            {info.getValue<any>()}
          </Text>
        ),
        header: (info) => (
          <Text
            as="p"
            className="pb-3 text-left text-[14px] font-normal text-neutral-neutral_500__day_"
          >
            Market
          </Text>
        ),
        meta: { width: "8%" },
      }),
      table3ColumnHelper.accessor("nameLabel", {
        cell: (info) => (
          <Text
            as="p"
            className="text-[14px] font-normal text-neutral-neutral_1100__day_"
          >
            {info.getValue<any>()}
          </Text>
        ),
        header: (info) => (
          <Text
            as="p"
            className="pb-3 text-left text-[14px] font-normal text-neutral-neutral_500__day_"
          >
            Name
          </Text>
        ),
        meta: { width: "9%" },
      }),
      table3ColumnHelper.accessor("averageLabel", {
        cell: (info) => {
          const currentValue = Number(Number(info.getValue<any>()).toFixed(0)) || 0;

          const allValues = Object.values(info.row.original.booksLabel).map(
            (book: any) => book?.ao || 0
          );

          const maxValue = Math.max(...allValues);
      
          return (
            <button
              className="w-[100%] rounded-lg h-[28px] border"
              style={{
                backgroundColor: currentValue === maxValue
                  ? "rgba(80, 209, 178, 0.2)" 
                  : currentValue < 0
                  ? "#E8EDF2" 
                  : "#E8EDF2",
                color: currentValue === maxValue
                  ? "#2B9B80" 
                  : currentValue < 0
                  ? "black" 
                  : "black",
              }}
              disabled={!info.getValue<any>()}
              onClick={() =>
                window.open(info.getValue<any>()?.wl || "#", "_blank")
              }
            >
              {currentValue || 0}
            </button>
          );
        },
        header: (info) => (
          <img
            alt=""
            src="./Images/BooksIcon/LogoDark.png"
            style={{ width: "30%", height: "15%", marginLeft:"25%" }}
          />
        ),
        meta: { width: "4%" },
      }),
      table3ColumnHelper.accessor("booksLabel.fanduel", {
        cell: (info) => {
          const currentValue = info.getValue<any>()?.ao || 0;

          const allValues = Object.values(info.row.original.booksLabel).map(
            (book: any) => book?.ao || 0
          );

          const maxValue = Math.max(...allValues);
      
          return (
            <button
              className="w-[100%] rounded-lg h-[28px] border"
              style={{
                backgroundColor: currentValue === maxValue
                  ? "rgba(80, 209, 178, 0.2)" 
                  : currentValue < 0
                  ? "#E8EDF2" 
                  : "#E8EDF2",
                color: currentValue === maxValue
                  ? "#2B9B80" 
                  : currentValue < 0
                  ? "black" 
                  : "black",
              }}
              disabled={!info.getValue<any>()}
              onClick={() =>
                window.open(info.getValue<any>()?.wl || "#", "_blank")
              }
            >
              {currentValue || 0}
            </button>
          );
        },
        header: (info) => (
          <img
            alt=""
            src="./Images/BooksIcon/fanduel.png"
            style={{ width: "30%", height: "15%", marginLeft:"25%" }}
          />
        ),
        meta: { width: "4%" },
      }),
      table3ColumnHelper.accessor("booksLabel.draftkings", {
        cell: (info) => {
          const currentValue = info.getValue<any>()?.ao || 0;
      
          const allValues = Object.values(info.row.original.booksLabel).map(
            (book: any) => book?.ao || 0
          );
      

          const maxValue = Math.max(...allValues);
      
          return (
            <button
              className="w-[100%] rounded-lg h-[28px] border"
              style={{
                backgroundColor: currentValue === maxValue
                  ? "rgba(80, 209, 178, 0.2)" 
                  : currentValue < 0
                  ? "#E8EDF2" 
                  : "#E8EDF2",
                color: currentValue === maxValue
                  ? "#2B9B80" 
                  : currentValue < 0
                  ? "black" 
                  : "black",
              }}
              disabled={!info.getValue<any>()}
              onClick={() =>
                window.open(info.getValue<any>()?.wl || "#", "_blank")
              }
            >
              {currentValue || 0}
            </button>
          );
        },
        header: (info) => (
          <img
            alt=""
            src="./Images/BooksIcon/draftkings.png"
            style={{ width: "45%", height: "20%", marginLeft:"25%" }}
          />
        ),
        meta: { width: "4%" },
      }),
      table3ColumnHelper.accessor("booksLabel.betmgm", {
        cell: (info) => {
          const currentValue = info.getValue<any>()?.ao || 0;

          const allValues = Object.values(info.row.original.booksLabel).map(
            (book: any) => book?.ao || 0
          );
      

          const maxValue = Math.max(...allValues);
      
          return (
            <button
              className="w-[100%] rounded-lg h-[28px] border"
              style={{
                backgroundColor: currentValue === maxValue
                  ? "rgba(80, 209, 178, 0.2)" 
                  : currentValue < 0
                  ? "#E8EDF2" 
                  : "#E8EDF2",
                color: currentValue === maxValue
                  ? "#2B9B80" 
                  : currentValue < 0
                  ? "black" 
                  : "black",
              }}
              disabled={!info.getValue<any>()}
              onClick={() =>
                window.open(info.getValue<any>()?.wl || "#", "_blank")
              }
            >
              {currentValue || 0}
            </button>
          );
        },
        header: (info) => (
          <img
            alt=""
            src="./Images/BooksIcon/BetMGM.png"
            style={{ width: "35%", height: "15%", marginLeft:"25%"}}
          />
        ),
        meta: { width: "4%" },
      }),
      table3ColumnHelper.accessor("booksLabel.bet365", {
        cell: (info) => {
          const currentValue = info.getValue<any>()?.ao || 0;
      

          const allValues = Object.values(info.row.original.booksLabel).map(
            (book: any) => book?.ao || 0
          );
      

          const maxValue = Math.max(...allValues);
      
          return (
            <button
              className="w-[100%] rounded-lg h-[28px] border"
              style={{
                backgroundColor: currentValue === maxValue
                  ? "rgba(80, 209, 178, 0.2)" 
                  : currentValue < 0
                  ? "#E8EDF2" 
                  : "#E8EDF2",
                color: currentValue === maxValue
                  ? "#2B9B80" 
                  : currentValue < 0
                  ? "black" 
                  : "black",
              }}
              disabled={!info.getValue<any>()}
              onClick={() =>
                window.open(info.getValue<any>()?.wl || "#", "_blank")
              }
            >
              {currentValue || 0}
            </button>
          );
        },
        header: (info) => (
          <img
            alt=""
            src="./Images/BooksIcon/bet365.png"
            style={{ width: "40%", height: "3%", marginLeft:"25%" }}
          />
        ),
        meta: { width: "4%" },
      }),
      table3ColumnHelper.accessor("booksLabel.fanatics", {
        cell: (info) => {
          const currentValue = info.getValue<any>()?.ao || 0;

          const allValues = Object.values(info.row.original.booksLabel).map(
            (book: any) => book?.ao || 0
          );

          const maxValue = Math.max(...allValues);
      
          return (
            <button
              className="w-[100%] rounded-lg h-[28px] border"
              style={{
                backgroundColor: currentValue === maxValue
                  ? "rgba(80, 209, 178, 0.2)" 
                  : currentValue < 0
                  ? "#E8EDF2" 
                  : "#E8EDF2",
                color: currentValue === maxValue
                  ? "#2B9B80" 
                  : currentValue < 0
                  ? "black" 
                  : "black",
              }}
              disabled={!info.getValue<any>()}
              onClick={() =>
                window.open(info.getValue<any>()?.wl || "#", "_blank")
              }
            >
              {currentValue || 0}
            </button>
          );
        },
          header: (info) => (
          <img
            alt=""
            src="./Images/BooksIcon/fanatics.webp"
            style={{ width: "40%", height: "3%", marginLeft:"25%"  }}
          />
        ),
        meta: { width: "4%" },
      }),
      table3ColumnHelper.accessor("booksLabel.espnbet", {
        cell: (info) => {
          const currentValue = info.getValue<any>()?.ao || 0;
      

          const allValues = Object.values(info.row.original.booksLabel).map(
            (book: any) => book?.ao || 0
          );
      

          const maxValue = Math.max(...allValues);
      
          return (
            <button
              className="w-[100%] rounded-lg h-[28px] border"
              style={{
                backgroundColor: currentValue === maxValue
                  ? "rgba(80, 209, 178, 0.2)" 
                  : currentValue < 0
                  ? "#E8EDF2" 
                  : "#E8EDF2",
                color: currentValue === maxValue
                  ? "#2B9B80" 
                  : currentValue < 0
                  ? "black" 
                  : "black",
              }}
              disabled={!info.getValue<any>()}
              onClick={() =>
                window.open(info.getValue<any>()?.wl || "#", "_blank")
              }
            >
              {currentValue || 0}
            </button>
          );
        },
        header: (info) => (
          <img
            alt=""
            src="./Images/BooksIcon/espnbet.jpeg"
            style={{ width: "40%", height: "3%", marginLeft:"25%" }}
          />
        ),
        meta: { width: "4%" },
      }),
      table3ColumnHelper.accessor("booksLabel.caesars", {
        cell: (info) => {
          const currentValue = info.getValue<any>()?.ao || 0;
      

          const allValues = Object.values(info.row.original.booksLabel).map(
            (book: any) => book?.ao || 0
          );
      

          const maxValue = Math.max(...allValues);
      
          return (
            <button
              className="w-[100%] rounded-lg h-[28px] border"
              style={{
                backgroundColor: currentValue === maxValue
                  ? "rgba(80, 209, 178, 0.2)" 
                  : currentValue < 0
                  ? "#E8EDF2" 
                  : "#E8EDF2",
                color: currentValue === maxValue
                  ? "#2B9B80" 
                  : currentValue < 0
                  ? "black" 
                  : "black",
              }}
              disabled={!info.getValue<any>()}
              onClick={() =>
                window.open(info.getValue<any>()?.wl || "#", "_blank")
              }
            >
              {currentValue || 0}
            </button>
          );
        },
        header: (info) => (
          <img
            alt=""
            src="./Images/BooksIcon/caesars.webp"
            style={{ width: "40%", height: "3%", marginLeft:"25%" }}
          />
        ),
        meta: { width: "4%" },
      }),
      table3ColumnHelper.accessor("booksLabel.pinnacle", {
        cell: (info) => {
          const currentValue = info.getValue<any>()?.ao || 0;

          const allValues = Object.values(info.row.original.booksLabel).map(
            (book: any) => book?.ao || 0
          );
      

          const maxValue = Math.max(...allValues);
      
          return (
            <button
              className="w-[100%] rounded-lg h-[28px] border"
              style={{
                backgroundColor: currentValue === maxValue
                  ? "rgba(80, 209, 178, 0.2)" 
                  : currentValue < 0
                  ? "#E8EDF2" 
                  : "#E8EDF2",
                color: currentValue === maxValue
                  ? "#2B9B80" 
                  : currentValue < 0
                  ? "black" 
                  : "black",
              }}
              disabled={!info.getValue<any>()}
              onClick={() =>
                window.open(info.getValue<any>()?.wl || "#", "_blank")
              }
            >
              {currentValue || 0}
            </button>
          );
        },
        header: (info) => (
          <img
            alt=""
            src="./Images/BooksIcon/Pinnacle.jpeg"
            style={{ width: "35%", height: "8%",marginLeft:"25%"  }}
          />
        ),
        meta: { width: "4%" },
      }),
      table3ColumnHelper.accessor("booksLabel.fliff", {
        cell: (info) => {
          const currentValue = info.getValue<any>()?.ao || 0;
          const allValues = Object.values(info.row.original.booksLabel).map(
            (book: any) => book?.ao || 0
          );
  
          const maxValue = Math.max(...allValues);
      
          return (
            <button
              className="w-[100%] rounded-lg h-[28px] border"
              style={{
                backgroundColor: currentValue === maxValue
                  ? "rgba(80, 209, 178, 0.2)" 
                  : currentValue < 0
                  ? "#E8EDF2" 
                  : "#E8EDF2",
                color: currentValue === maxValue
                  ? "#2B9B80" 
                  : currentValue < 0
                  ? "black" 
                  : "black",

              }}
              disabled={!info.getValue<any>()}
              onClick={() =>
                window.open(info.getValue<any>()?.wl || "#", "_blank")
              }
            >
              {currentValue || 0}
            </button>
          );
        },
        header: (info) => (
          <img
            alt=""
            src="./Images/BooksIcon/getfliff.jpeg"
            style={{ width: "35%", height: "12%", marginLeft: "25%" }}
          />
        ),
        meta: { width: "4%" },
      }),
      table3ColumnHelper.accessor("booksLabel.betrivers", {
        cell: (info) => {
          const currentValue = info.getValue<any>()?.ao || 0;

          const allValues = Object.values(info.row.original.booksLabel).map(
            (book: any) => book?.ao || 0
          );

          const maxValue = Math.max(...allValues);
      
          return (
            <button
              className="w-[100%] rounded-lg h-[28px] border"
              style={{
                backgroundColor: currentValue === maxValue
                  ? "rgba(80, 209, 178, 0.2)" 
                  : currentValue < 0
                  ? "#E8EDF2" 
                  : "#E8EDF2",
                color: currentValue === maxValue
                  ? "#2B9B80" 
                  : currentValue < 0
                  ? "black" 
                  : "black",
              }}
              disabled={!info.getValue<any>()}
              onClick={() =>
                window.open(info.getValue<any>()?.wl || "#", "_blank")
              }
            >
              {currentValue || 0}
            </button>
          );
        },
        header: (info) => (
          <img
            alt=""
            src="./Images/BooksIcon/BetRivers.jpeg"
            style={{ width: "35%", height: "7%",marginLeft:"25%"}}
          />
        ),
        meta: { width: "4%" },
      }),
      table3ColumnHelper.accessor("booksLabel.hardrock", {
        cell: (info) => {
          const currentValue = info.getValue<any>()?.ao || 0;
      

          const allValues = Object.values(info.row.original.booksLabel).map(
            (book: any) => book?.ao || 0
          );
      

          const maxValue = Math.max(...allValues);
      
          return (
            <button
              className="w-[100%] rounded-lg h-[28px] border"
              style={{
                backgroundColor: currentValue === maxValue
                  ? "rgba(80, 209, 178, 0.2)" 
                  : currentValue < 0
                  ? "#E8EDF2" 
                  : "#E8EDF2",
                color: currentValue === maxValue
                  ? "#2B9B80" 
                  : currentValue < 0
                  ? "black" 
                  : "black",
              }}
              disabled={!info.getValue<any>()}
              onClick={() =>
                window.open(info.getValue<any>()?.wl || "#", "_blank")
              }
            >
              {currentValue || 0}
            </button>
          );
        },
      
        header: (info) => (
          <img
            alt=""
            src="./Images/BooksIcon/hardrock.webp"
            style={{ width: "50%", height: "4%", marginLeft:"25%" }}
          />
        ),
        meta: { width: "4%" },
      }),
    ];
  }, []);

  return (
    <>
      <Helmet>
        <title> Sports Optimizer Betting - Find the Best Odds</title>
        <meta
          name="description"
          content="Engage in sports optimizer betting and ensure the best payouts with our comprehensive odds comparison from top bookmakers like MGM, FD, DK, and ESPN."
        />
      </Helmet>
      <div className="flex">
        <Sidebar1 />
        <div className="w-full bg-neutral-neutral_100__day_">
          <div>
            <div className="relative h-[90px] md:h-auto">
              <Header />
            </div>

            <div className="ml-4 h-px bg-neutral-neutral_border__day_ md:ml-0" />

            <div className="mr-7 flex items-start gap-[30px] md:mr-0">
              <div className="flex-1">
                <div className="flex  ml-5 py-2  md:self-stretch md:px-5">
                  <Input
                    name="Search Bar"
                    placeholder="Enter your keyword..."
                    value={keywordOption}
                    onChange={(kw) => setKeywordOption(kw.target.value)}
                    prefix={
                      <Img
                        src="/Images/Dashboard-img/search.png"
                        alt="Contrast"
                        className="my-1 h-[100%] w-[100%]"
                      />
                    }
                    suffix={
                      keywordOption.length > 0 ? (
                        <CloseCircle onClick={() => setKeywordOption("")} />
                      ) : null
                    }
                    className="flex h-[48px] w-[18%] items-center justify-center gap-3  rounded-lg border border-solid border-neutral-neutral_border__day_ bg-neutral-neutral_bg__day_ px-3 text-[10px] text-blue_gray-300 md:w-full  md:px-5"
                  />
                  <AntSelectBox
                    placeholder="League"
                    value={leagueOption}
                    onChange={(opt: React.SetStateAction<options[]>) =>
                      setLeagueOption(opt)
                    }
                    className="ml-3"
                  />

                  <AntSelectBox
                    placeholder="Market"
                    value={marketOption}
                    options={marketOptions}
                    onChange={(opt: options[]) => setMarketOption(opt)}
                    className="ml-3  w-[15%]"
                  />
                  <Input
                    placeholder="Odds"
                    className="flex h-[48px] w-[18%] items-center justify-center gap-3 ml-3  rounded-lg border border-solid border-neutral-neutral_border__day_ bg-neutral-neutral_bg__day_ px-3 text-[10px] text-blue_gray-300 md:w-full  md:px-5"
                  />
                </div>

                <div className="rounded-[16px] ml-5 border border-solid border-neutral-neutral_border__day_ bg-neutral-neutral_bg__day_ p-5">
                <div className="flex ">
                <p className=" text-left mr-2 text-[14px]  font-normal text-neutral-neutral_500__day_" > Auto refresh:{countdown} </p>
                <button onClick={handleFetchOptimizer} >    <Refresh className="w-[15px] h-[15px] " /> 
                </button>
                </div>
                  <ReactTable
                    size="xs"
                    bodyProps={{ className: "" }}
                    cellProps={{
                      className: "border-gray-300 border-b-2 border-solid",
                    }}
                    className="mb-2 md:block md:overflow-x-auto md:whitespace-nowrap"
                    columns={table3Columns}
                    data={filteredOdds}
                  />
                </div>
               </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
