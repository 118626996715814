import { GOOGLE_SIGN_IN_CLIENT_ID } from "components/const/variables";
import { GoogleLogin } from "react-google-login";

export const GoogleSignIn = () => {
  const onSuccess = (response: any) => {
    console.log("Login Success! Current User:", response);
    // Send response.tokenId to your backend for verification
  };

  const onFailure = (response: any) => {
    console.error("Login failed:", response);
  };

  return (
    <div>
      <GoogleLogin
        clientId={GOOGLE_SIGN_IN_CLIENT_ID}
        buttonText="Sign in with Google"
        onSuccess={onSuccess}
        onFailure={onFailure}
        cookiePolicy={"single_host_origin"}
      />
    </div>
  );
};