import React, { useState } from "react";
import {  Messages1, Notification, } from 'iconsax-react';
import { useNavigate } from "react-router-dom";
import { CardEdit,Setting2 } from 'iconsax-react';

interface Props {
  className?: string;
}


export default function Header({ ...props }: Props) {

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();

  const handleEditProfile = () => {
    navigate("/EditProfile"); // Cambia esta ruta a la que necesites
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  }
  return (
    <header {...props} className={`${props.className} flex items-end mx-auto bg-neutral-neutral_bg__day_ flex-1`}>
      <div className="mt-[18px] flex w-full flex-col items-start  ml-10 py-2 " >
        <div className="mx-auto flex w-full max-w-[1830px] items-center justify-between gap-5 md:flex-col md:px-5">
          <div className="flex w-[88%] justify-end gap-5 md:w-full sm:flex-col">

            <div className="flex gap-8  py-2" >
              <Messages1/>
              <Notification/>
              </div>
            <div className="relative">

                    <img
                src="/Images/Dashboard-img/topbar.png"
                alt="Profile Link"
                className="h-[40px] w-[40px] rounded-[24px] object-cover cursor-pointer sm:w-full"
                onClick={toggleMenu}
                    />


               {isMenuOpen && (
                 <ul
                   role="menu"
                   className="absolute z-10 min-w-[170px] mt-1 overflow-auto rounded-lg border border-slate-200 bg-white-a700 p-1 shadow-lg shadow-sm focus:outline-none"
                 >
                  
                   <li
                      role="menuitem"
                      className="cursor-pointer text-slate-800 flex w-full text-sm items-center rounded-md p-3 transition-all hover:bg-slate-100 focus:bg-slate-100 active:bg-slate-100"
                   >
                      <CardEdit  className="w-15 h-15 text-slate-400" />
                     <p className="text-slate-800 font-[10px] ml-2">Manage subscriptions</p>
                   </li>
                

                   <li
                     role="menuitem"
                     className="cursor-pointer text-slate-800 flex w-full text-sm items-center rounded-md p-3 transition-all hover:bg-slate-100 focus:bg-slate-100 active:bg-slate-100"
                     onClick={handleEditProfile}
                   >
                       <Setting2 className="w-5 h-5 text-slate-400" />
                   
                     <p className="text-slate-800 font-[10px] ml-2">Edit Profile</p>
                   </li>
                </ul>
              )}
             </div>
  

          </div>
        </div>
     </div>
    </header>
  );
}
