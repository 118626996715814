import { Helmet } from "react-helmet";
import { Text, Button, Img, Input, Heading } from "../../../../components";
import Header from "../../../../components/Header";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

export default function EditProfile() {
    const navigate = useNavigate();
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
      const [showPassword, setShowPassword] = useState(false);
   
      const togglePasswordVisibility = () => 
        { setShowPassword(!showPassword); 
 
      };
 
       const toggleConfirmPasswordVisibility = () => { 
        setShowConfirmPassword(!showConfirmPassword); 
      };
    const handleSave = () => {
      // Aquí puedes añadir la lógica de registro, validaciones, etc.
      // Después de eso, redirige al usuario a la página deseada
      navigate('/PaidDashboard'); // Asegúrate de que esta ruta sea correcta y esté definida en tu enrutador
    };


  return (
    <>
      <Helmet>
        <title>Edit your profile</title>
      </Helmet>
      <div className="flex w-full min-h-screen flex-col items-center gap-[84px] bg-neutral-neutral_border__day_ md:gap-[63px] sm:gap-[42px]">
        <Header />
        <div className="flex w-full max-w-md sm:min-w-[310px]  min-w-[320px] min-h-[500px] items-center justify-center bg-white-a700 border border-neutral-neutral_border__day_ rounded-[16px] p-[34px] md:w-full sm:p-8">
          <div className="w-full flex flex-col items-center gap-[22px]">
            <div className="mt-1 flex w-full max-w-xs flex-col items-center gap-2 md:w-full">
              <div className="flex flex-col items-center self-stretch">
              <Heading size="heading_h5" as="h1" className="!text-neutral-neutral_1100__day_  ">
                 Edit Profile
                </Heading>
              </div>
            </div>
            

           <div className="flex flex-col items-center gap-[18px] self-stretch">
           <div className="flex gap-4">
                  <div className="flex flex-col font-extralight">
                  <Text as="p" className="font-light mb-1">
                    Name
                  </Text>
                  <Input
                    shape="round"
                    type="text"
                    name="Name Field"
                    placeholder="First Name"
                    suffix={
                      <Img
                        src="./Images/SignUp-img/user.png"
                        alt="User Icon"
                        className="h-4 w-4"
                      />
                    }
                     className="self-stretch border border-solid border-neutral-neutral_border_day_"
                  />
                  </div>

                  <div className="flex flex-col justify-between font-extralight">
                  <Text as="p" className="font-light mb-1"></Text>
                  <Input
                    shape="round"
                    type="text"
                    name="Name Field"
                    placeholder="Last Name"
                    suffix={
                      <Img
                        src="./Images/SignUp-img/user.png"
                        alt="User Icon"
                        className="h-4 w-4"
                      />
                    }
                     className="self-stretch border border-solid border-neutral-neutral_border_day_"
                  />
                  </div>
                </div>

              <div className="flex flex-col font-extralight items-start gap-1 self-stretch">
                <Text as="p" className="font-light">
                  E-mail
                </Text>
                <Input
                  shape="round"
                  type="email"
                  name="Email Field"
                  placeholder="Email"
                  suffix={
                    <Img
                      src="./Images/sms.png"
                      alt="SMS Icon"
                      className="h-4 w-4"
                    />
                  }
                  className="self-stretch border border-solid border-neutral-neutral_border_day_"
                />
              </div>



              <div className="flex flex-col items-start gap-1 font-extralight self-stretch">
                <Text as="p" className="font-light">
                  Phone Number
                </Text>
                <Input
                  shape="round"
                  type="tel"
                  name="Phone Field"
                  placeholder="Phone Number"
                
                  suffix={
                    <Img
                      src="./Images/SignUp-img/call.png"
                      alt="Call Icon"
                      className="h-4 w-4"
                    />
                  }
                  className="self-stretch border border-solid border-neutral-neutral_border_day_"
                />
              </div>
                            <div className="flex flex-col font-extralight items-start gap-1 self-stretch">
                              <Text as="p" className="font-light">
                                Password
                              </Text>
                              <div className="relative w-full">
                                <Input
                                  shape="round"
                                  type={showPassword ? "text" : "password"}
                                  name="Password Field"
                                  placeholder="Password"
                           
                                  className="gap-4 self-stretch border border-solid border-neutral-neutral_border_day_ pr-10"
                                />
                                <div
                                  className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
                                  onClick={togglePasswordVisibility}
                                >
                                  <Img
                                    src={showPassword ? "./Images/Eye off.png" : "./Images/eye.png"}
                                    alt="Eye Icon"
                                    className="h-[16px] w-[16px]"
                                  />
                                </div>
                              </div>
                              </div>
                            <div className="flex flex-col font-extralight items-start gap-1 self-stretch">
                              <Text as="p" className="font-light">
                                Confirm Password
                              </Text>
                            <div className="relative w-full">
                                <Input
                                  shape="round"
                                  type={showConfirmPassword ? "text" : "password"}
                                  name="confirmPassword Field"
                                  placeholder="Confirm Password"
                                  
                                  className="gap-4 self-stretch border border-solid border-neutral-neutral_border_day_ pr-10"
                                />
                                <div
                                  className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
                                  onClick={toggleConfirmPasswordVisibility}
                                >
                                  <Img
                                    src={showConfirmPassword ? "./Images/Eye off.png" : "./Images/eye.png"}
                                    alt="Eye Icon"
                                    className="h-[16px] w-[16px]"
                                  />
                                </div>
                              </div>
                              </div>
              <Button
                size="sm"
                variant="gradient"
                shape="round"
                color="blue_A200_purple_A200"
                onClick={handleSave}
                className="self-stretch font-semibold text-white-a700"
              >
                Save Changes
              </Button>
              <a href="/#" className="self-end">
              </a>
              
            </div>
          </div>
        </div>
      </div>
    </>
  );
}


