/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import { Helmet } from "react-helmet";
import { Heading, Text, } from "../../../components/Dashboard";
import { ReactTable } from "../../../components/Dashboard/ReactTable";

import { createColumnHelper } from "@tanstack/react-table";
import React, { useMemo, useState } from "react";
import Sidebar1 from "components/Dashboard/Sidebar1";


import Header from "components/Dashboard/Header";


type Table3RowType = {
  offerLabel:string;
  states:string
  booksLabel: booksLabel | string;
  
};

type booksLabel = {
  book1: book;
  
};

type book = {
  name: string;
  odds: string;
  link: string;
};






export default function ReferralsPage () {

      const [filteredArbs] = useState<Table3RowType[]>([
        {
          offerLabel: "Bet $5, Get $150",
          states: "NY, NJ, PA",
          booksLabel: {
            book1: {
              name: "Draftkings",
              odds: "+150",
              link: "https://www.draftkings.com/",
            },
          },
        },
        {
          offerLabel: "Risk-Free Bet up to $1000",
          states: "CA, NV",
          booksLabel: {
            book1: {
              name: "BetMGM",
              odds: "+200",
              link: "https://www.betmgm.com/",
            },
          },
        },
        {
          offerLabel: "Get $200 Free Bets",
          states: "TX, FL",
          booksLabel: {
            book1: {
              name: "Fanatics",
              odds: "+250",
              link: "https://www.fanatics.com/",
            },
          },
        },
        {
          offerLabel: "Get $200 Free Bets",
          states: "TX, FL",
          booksLabel: {
            book1: {
              name: "Fliff",
              odds: "+250",
              link: "https://www.Fliff.com/",
            },
          },
        },
        {
          offerLabel: "Get $200 Free Bets",
          states: "TX, FL",
          booksLabel: {
            book1: {
              name: "ESPNBet",
              odds: "+250",
              link: "https://www.ESPNBet.com/",
            },
          },
        },
        {
          offerLabel: "Get $200 Free Bets",
          states: "TX, FL",
          booksLabel: {
            book1: {
              name: "Caesars",
              odds: "+250",
              link: "https://www.Caesars.com/",
            },
          },
        },
        {
          offerLabel: "Get $200 Free Bets",
          states: "TX, FL",
          booksLabel: {
            book1: {
              name: "BetRivers",
              odds: "+250",
              link: "https://www.BetRivers.com/",
            },
          },
        },
        {
          offerLabel: "Get $200 Free Bets",
          states: "TX, FL",
          booksLabel: {
            book1: {
              name: "Fanduel",
              odds: "+250",
              link: "https://www.fanatics.com/",
            },
          },
        },
        {
          offerLabel: "Get $200 Free Bets",
          states: "TX, FL",
          booksLabel: {
            book1: {
              name: "Pinnacle",
              odds: "+250",
              link: "https://www.fanatics.com/",
            },
          },
        },
        {
          offerLabel: "Get $200 Free Bets",
          states: "TX, FL",
          booksLabel: {
            book1: {
              name: "Hardrock",
              odds: "+250",
              link: "https://www.Hardrock.com/",
            },
          },
        },
        {
          offerLabel: "Get $200 Free Bets",
          states: "TX, FL",
          booksLabel: {
            book1: {
              name: "Bet365",
              odds: "+250",
              link: "https://www.fanatics.com/",
            },
          },
        },
      ]);

  
  const table3Columns = useMemo(() => {
    const table3ColumnHelper = createColumnHelper<Table3RowType>();
    return [
   
      
      table3ColumnHelper.accessor("booksLabel", {
        cell: (info) => (
          <div className="button-container">
            {info.getValue<booksLabel>().book1.name === "Fliff" && (
              <img
              alt=""
              src="./Images/Referrals/Fliff.png"
                style={{ width: "25%", height: "15%" }}
              />
            )}
            {info.getValue<booksLabel>().book1.name === "ESPNBet" && (
              <img
              alt=""
              src="./Images/Referrals/ESPN.png"
                style={{ width: "20%", height: "15%" }}
              />
            )}
            {info.getValue<booksLabel>().book1.name === "Fanatics" && (
              <img
              alt=""
              src="./Images/Referrals/Fanatics.png"
                style={{ width: "35%", height: "15%" }}
              />
            )}
            {info.getValue<booksLabel>().book1.name === "Caesars" && (
              <img
              alt=""
              src="./Images/Referrals/Caesars.png"
                style={{ width: "35%", height: "15%" }}
              />
            )}

            {info.getValue<booksLabel>().book1.name === "BetRivers" && (
              <img
              alt=""
              src="./Images/Referrals/Betrivers.png"
                style={{ width: "30%", height: "15%" }}
              />
            )}
            {info.getValue<booksLabel>().book1.name === "BetMGM" && (
              <img
              alt=""
              src="./Images/Referrals/betMGM.png"
                style={{ width: "25%", height: "15%" }}
              />
            )}
            {info.getValue<booksLabel>().book1.name === "fanduel" && (
              <img
              alt=""
              src="./Images/Referrals/Fanduel.png"
                style={{ width: "35%", height: "15%" }}
              />
            )}
            {info.getValue<booksLabel>().book1.name === "Fanduel" && (
              <img
              alt=""
              src="./Images/Referrals/Fanduel.png"
                style={{ width: "35%", height: "15%" }}
              />
            )}
            {info.getValue<booksLabel>().book1.name === "Draftkings" && (
              <img
              alt=""
              src="./Images/Referrals/dk.png"
                style={{ width: "30%", height: "15%" }}
              />
            )}
            {info.getValue<booksLabel>().book1.name === "draftkings" && (
              <img
              alt=""
              src="./Images/Referrals/dk.png"
                style={{ width: "30%", height: "15%" }}
              />
            )}
            {info.getValue<booksLabel>().book1.name === "Pinnacle" && (
              <img
              alt=""
              src="./Images/Referrals/Pinnacle.png"
                style={{ width: "35%", height: "15%" }}
              />
            )}
            {info.getValue<booksLabel>().book1.name === "Hardrock" && (
              <img
              alt=""
              src="./Images/Referrals/Hardrock.png"
                style={{ width: "25%", height: "15%" }}
              />
            )}
              {info.getValue<booksLabel>().book1.name === "Bet365" && (
              <img
              alt=""
              src="./Images/Referrals/bet365.png"
                style={{ width: "30%", height: "15%" }}
              />
            )}
          </div>
        ),
        header: (info) => 
        <Text as="p"
         className="pb-3 text-left text-[14px] font-normal text-neutral-neutral_500__day_"
        > Thumbnail</Text>,

        meta: { width: "20%" },
      }),
            table3ColumnHelper.accessor("booksLabel.book1.name", {
              cell: (info) => (
                <Text
                  as="p"
                  className="text-[14px] font-normal text-neutral-neutral_1100__day_"
                >
                  {info.getValue<any>()}
                </Text>
              ),
              header: (info) => (
                <Text
                  as="p"
                  className="pb-3 text-left text-[14px] font-normal text-neutral-neutral_500__day_"
                >
                  BOOK
                </Text>
              ),
              meta: { width: "15%" },
            }),
            table3ColumnHelper.accessor("offerLabel", {
                cell: (info) => (
                  <Text
                    as="p"
                    className="text-[14px] font-normal text-neutral-neutral_1100__day_"
                  >
                    {info.getValue<any>()}
                  </Text>
                ),
                header: (info) => (
                  <Text
                    as="p"
                    className="pb-3 text-left text-[14px] font-normal text-neutral-neutral_500__day_"
                  >
                    OFFER
                  </Text>
                ),
                meta: { width: "15%" },
              }),
              table3ColumnHelper.accessor("states", {
                cell: (info) => (
                  <Text
                    as="p"
                    className="text-[14px] font-normal text-neutral-neutral_1100__day_"
                  >
                    {info.getValue<any>()}
                  </Text>
                ),
                header: (info) => (
                  <Text
                    as="p"
                    className="pb-3 text-left text-[14px] font-normal text-neutral-neutral_500__day_"
                  >
                    STATES
                  </Text>
                ),
                meta: { width: "10%" },
              }),
              table3ColumnHelper.accessor("booksLabel.book1.link", {
                cell: (info) => (
                        <button
                        className=" font-poppins font-semibold "
                        style={{
                          width: "7rem",
                          padding: "5px",
                          fontSize: "12px",
                          color: "#c14bff ", 
                          backgroundColor: "white", 
                          border: "1px solid transparent",
                          backgroundImage:
                            "linear-gradient(white, white), linear-gradient(220deg, #5c8fff, #c14bff)",
                          backgroundOrigin: "border-box",
                          backgroundClip: "padding-box, border-box", 
                          borderRadius: "10px",
                        }}
                        >
                            CLAIM OFFER
                        </button>
                ),
                header: (info) => (
                  <Text
                    as="p"
                    className="pb-3 text-left text-[14px] font-normal text-neutral-neutral_500__day_"
                  >
                    CLAIM
                  </Text>
                ),
                meta: { width: "7%" },
              }),
    ];
    
  }, []);

  return (
    <>
      <Helmet>
        <title> Sports Arbitrage Betting - Find the Best Odds</title>
        <meta
          name="description"
          content="Engage in sports arbitrage betting and ensure the best payouts with our comprehensive odds comparison from top bookmakers like MGM, FD, DK, and ESPN."
        />
      </Helmet>
      <div className="flex">
        <Sidebar1 />
        <div className="w-full bg-neutral-neutral_100__day_">
          <div>
            
          <div className="relative  h-[10rem] md:h-auto">
              <Header />

              <Heading
                 size="heading_h3"
                 as="h1"
                 className="text-[28px] ml-8 m-4 font-bold text-neutral_neutral_1100__day_ md:text-[26px] sm:text-[24px]"
                  >
                        Sign-up Bonus Offers 

                      </Heading>
            </div>
            <div className="mr-7 flex items-start gap-[30px] md:mr-0">
              <div className="flex-1">
             
                <div className="rounded-[16px] ml-5 border border-solid border-neutral-neutral_border__day_ bg-neutral-neutral_bg__day_ p-5">
                  <ReactTable
                    size="xs"
                    bodyProps={{ className: "" }}
                    cellProps={{
                      className: "border-gray-300 border-b-2 border-solid",
                    }}
                    className="mb-2 md:block md:overflow-x-auto md:whitespace-nowrap"
                    columns={table3Columns}
                    data={filteredArbs}

                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
