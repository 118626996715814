import { Heading } from "../../../components/Dashboard";

import { Tweet } from "react-tweet";


export default function PaidDashboardSection() {
  return (
    <>
      {/* Paid dashboard main section */}
      <div className="flex gap-4 md:flex-col">

        <div className=" relative h-[670px] w-[395px] content-end rounded-[16px] border-[0.5px] border-solid border-neutral-neutral_border__day_ bg-neutral-neutral_bg__day_ md:h-auto">
          <div className="mx-6  flex items-center  md:mx-0">
            <Heading
              size="subtitle_semi_bold"
              as="h3"
              className="text-[16px] font-semibold text-neutral-neutral_1100__day_"
            >
              Live Feed
            </Heading>
          </div>
          <div className="flex relative w-[392px] h-[670px]">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
   
              }}
            >
              <div
                style={{
                  transform: "scale(0.8)", 
                  transformOrigin: "center",
                }}
              >
                <Tweet id="1850402272750006515" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
